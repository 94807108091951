import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Společenstva a jejich geografie" />
    <h1>Společenstva a jejich geografie</h1>

<p>Společenstva (nebo tak&eacute; <strong>cen&oacute;zy</strong>) jsou soubory populac&iacute; různ&yacute;ch druhů vyskytuj&iacute;c&iacute;ch se na jednom m&iacute;stě a v jednom čase. Někter&eacute; definice společenstva vyžaduj&iacute; interakce mezi populacemi na dan&eacute;m &uacute;zem&iacute;, jin&eacute; v&scaron;ak nikoliv.</p>
<p>S pojmem společenstvo souvis&iacute; i rozd&iacute;l mez&iacute; pojmy <strong>vegetace a fl&oacute;ra</strong>. Zat&iacute;mco vegetace je v ekologie ch&aacute;p&aacute;na jako společenstvo rostlin vyskytuj&iacute;c&iacute; se na určit&eacute;m &uacute;zem&iacute;, fl&oacute;ra je pak br&aacute;na jako seznam rostlinn&yacute;ch druhů určit&eacute;ho &uacute;zem&iacute;.</p>
<hr />
<h2>Z&aacute;kladn&iacute; dělen&iacute; společenstev</h2>
<p>S ohledem na krit&eacute;ria vymezen&iacute; děl&iacute;me společenstva do různ&yacute;ch skupin:</p>
<ul>
<li><strong>taxonomick&aacute; společenstva </strong>(tzv. taxoncen&oacute;za, soubor taxonomicky př&iacute;buzn&yacute;ch druhů, např. pt&aacute;ci, suchozem&scaron;t&iacute; plži atp.)</li>
<li><strong>funkčn&iacute; společenstva</strong>
<ul>
<li>guild (soubor druhů využ&iacute;vaj&iacute;c&iacute;ch podobn&yacute;m způsobem podobn&eacute; zdroje, např. garnivorn&iacute; guild)</li>
<li>synuzie (soubor druhů nesouc&iacute; určit&eacute; společn&eacute; ekologick&eacute; rysy a ob&yacute;vaj&iacute;c&iacute; konkr&eacute;tn&iacute; č&aacute;st biocen&oacute;zy, např. parazitocen&oacute;za kr&aacute;vy)</li>
</ul>
</li>
<li><strong>prostorov&aacute; společenstva</strong>
<ul>
<li>vymezen&eacute; přirozen&yacute;mi hranicemi (jezero, suťov&eacute; pole, mr&scaron;ina)</li>
<li>arbitr&aacute;lně vymezen&aacute; č&aacute;st &uacute;zem&iacute; (mapovac&iacute; kvadr&aacute;t)</li>
<li>vybran&yacute; biotop v určit&eacute;m &uacute;zem&iacute; (listnat&yacute; les, slanisko aj.)</li>
<li>podle dominance určit&yacute;ch druhů (bukov&yacute; les)</li>
<li>podle druhov&eacute;ho složen&iacute; (shluky taxonů defifnovan&eacute; statisticky z numerick&eacute; klasifikace)</li>
</ul>
</li>
</ul>
<p>V biogeografii se vět&scaron;inou ch&aacute;pe společenstvo jako prostorov&eacute;. Společenstvo lze mnohde rozdělit prostorově i jin&yacute;m způsoby, např&iacute;klad v lese podle vertik&aacute;ln&iacute;ho uspoř&aacute;d&aacute;n&iacute; na jednotliv&aacute; <strong>patra</strong> neboli <strong>biostrata</strong> (patro mechov&eacute;, bylinn&eacute;, keřov&eacute; a stromov&eacute;).</p>
<hr />
<h2>Struktura společenstev</h2>
<p>Strukturu prostorov&yacute;ch společenstev lze pochopit podle dvou protichůdn&yacute;ch pojet&iacute;ch:</p>
<ul>
<li><strong>organisim&aacute;ln&iacute; pojet&iacute;</strong> (mezi druhy existuj&iacute; siln&eacute; interakce a společenstva re&aacute;lně existuj&iacute; jako jak&eacute;si superorganismy či quasi-organismy)</li>
<li><strong>individualistick&eacute; pojet&iacute; </strong>(druhy se vyskytuj&iacute; na sobě zcela nez&aacute;visle a v&yacute;skyt i abundance druhů jsou z&aacute;visl&eacute; čistě na abiotick&eacute;m prostřed&iacute;, kter&eacute; tak definuje společenstva)</li>
</ul>
<p>Tato pojet&iacute; poch&aacute;z&iacute; z prvn&iacute; poloviny 20.&nbsp;stolet&iacute; a dnes v&iacute;me, že se navz&aacute;jem doplňuj&iacute; a nevylučuj&iacute; se. Prostorov&eacute; společenstvo a jeho struktura je tak podm&iacute;něna jak <Link to="/biogeografie/ekologicka/bioticke-interakce/">biotick&yacute;mi</Link>, tak <Link to="/biogeografie/ekologicka/abioticke-faktory/">abiotick&yacute;mi ekologick&yacute;mi faktory</Link>.</p>
<hr />
<h2>Sdružovac&iacute; pravidla</h2>
<p>Sdružovac&iacute; pravidla (anglicky <em>assembly rules</em>) jsou podle Keddyho (1992) v&scaron;echny ekologick&eacute; procesy, kter&eacute; podporuj&iacute; nebo znemožňuj&iacute; v&yacute;skyt druhů z region&aacute;ln&iacute;ho druhov&eacute;ho z&aacute;sobn&iacute;ku.</p>
<p>Tyto ekologick&eacute; procesy se chovaj&iacute; jako filtry br&aacute;n&iacute;c&iacute; ať už <Link to="/biogeografie/disperze/ekologicke-sireni/">&scaron;&iacute;řen&iacute; (dispersal)</Link>, v&yacute;běru dan&eacute;ho lok&aacute;ln&iacute;ho stanovi&scaron;tě (habitat selection) či se jako filtr může chovat př&iacute;tomnost, nebo naopak absence určit&eacute; <Link to="/biogeografie/ekologicka/bioticke-interakce/">mezidruhov&eacute; interakce (interspecific interactions)</Link>. V&yacute;skyty a abundance druhů jsou tak z&aacute;visl&eacute; nejen na prostřed&iacute;, ale tak&eacute; na př&iacute;tomnosti jin&yacute;ch druhů.</p>
<h3>Hypot&eacute;zy sdružovac&iacute;ch pravidel</h3>
<h4>Environmental (habitat) filtering hypothesis</h4>
<p>Hypot&eacute;za environment&aacute;ln&iacute;ho filtrov&aacute;n&iacute; (zn&aacute;měj&scaron;&iacute; pod anglick&yacute;m n&aacute;zvem <em>Environmental (habitat) filtering hypothesis</em>) předpokl&aacute;d&aacute;, že druhy si mus&iacute; b&yacute;t ekologicky a funkčně podobn&eacute;, aby mohly b&yacute;t př&iacute;tomny v dan&eacute;m společenstvu.</p>
<p>Druhy jednoho společenstva maj&iacute; podobn&eacute; n&aacute;roky na podm&iacute;nky prostřed&iacute; a zdroje, zat&iacute;mco druhy s v&yacute;razně jin&yacute;mi n&aacute;roky na prostřed&iacute; jsou ze společenstva vyloučeny (tzv. habitat filtering).</p>
<h4>Limiting similarity hypothesis</h4>
<p>Hypot&eacute;za maxim&aacute;ln&iacute; podobnosti (či anglicky <em>Limiting similarity hypothesis</em>) předpokl&aacute;d&aacute;, že druhy v jednom společenstvu si nesm&iacute; b&yacute;t až př&iacute;li&scaron; ekologicky a funkčně podobn&eacute;, protože pak by kvůli př&iacute;li&scaron; siln&eacute; konkurenci nemohly koexistovat.</p>
<p>Ve společenstvu nemohou dlouhodobě koexistovat druhy se stejn&yacute;mi <Link to="/biogeografie/ekologicka/ekologicke-faktory/">ekologick&yacute;mi nikami</Link>, jeden druh je ze společenstva vytlačen na z&aacute;kladě principu kompetitivn&iacute;ho vyloučen&iacute;.</p>
<hr />
<h2>Vybran&eacute; klasifikace společenstev</h2>
<p>V samostatn&yacute;ch kapitol&aacute;ch jsou rozeps&aacute;ny vybran&eacute; klasifikace prostorov&yacute;ch společenstev:</p>
<div className="od-sm">
<hr />
<h3><Link to="/biogeografie/spolecenstva/globalni-biomy/">Biomy (glob&aacute;ln&iacute; společenstva)</Link></h3>
<p>Rychl&yacute; pohled na glob&aacute;ln&iacute; prostorov&aacute; společenstva, tedy biomy. Stručn&eacute; pops&aacute;n&iacute; jednotliv&yacute;ch biomů z pohledu klimatu, půd a typu vegetace.</p>
<hr />
<h3><Link to="/biogeografie/spolecenstva/vegetace-evropy/">Společenstva a&nbsp;vegetace Evropy</Link></h3>
<p>Něco m&aacute;lo o evropsk&eacute; fytocenologii a fytocenologick&yacute;ch sn&iacute;mc&iacute;ch. Popis typů evropsk&yacute;ch společenstev z hlediska klimatu, půd a vegetace.</p>
<hr />
    </div>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Společenstva a jejich geografie</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/areografie/faktory-ovlivnujici-rozsireni-druhu/"><button className="inv">&larr; Faktory ovlivňující rozšíření druhu</button></Link>
    <Link to="/biogeografie/spolecenstva/globalni-biomy/"><button className="inv">Biomy (globální společenstva) &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
